.pushin {
  vertical-align: center;
  align-content: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.collapsible-header {
  display: block;
  border-color: #2c3a47;
  background-color: #2c3a47;
  color: white;
  font-family: "Josefin Sans", sans-serif;
  font-size: 150%;
  font-weight: 200;
}

.collapsible-header:active {
  border-bottom: #747474;
  overflow-y: hidden;
}

.collapsible-body {
  color: white;
  font-size: 150%;
  font-weight: 200;
  border-bottom-color: #2c3a47;
}
.collapsible.popout > li {
  margin: 0 45vmax;
  -webkit-transition: margin 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: margin 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
