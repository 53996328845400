.Title {
  padding-top: 8%;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 200;
  font-size: 650%;
  color: white;
}
.info {
  font-family: "Josefin Sans", sans-serif;
  color: white;
  font-size: 200%;
  padding-bottom: 1%;
}
.border {
  border: solid;
  border-width: 10px;
  border-radius: 1em;

  overflow: visible;
  border-color: #444444;
}

.pushin {
  vertical-align: center;
}

.container {
  width: 25%;
  display: flex;
  align-content: center;
  justify-content: center;
}
