.custbtn {
  font-family: "Josefin Sans", sans-serif;
  font-size: 225%;
  font-weight: 500;
  width: 100%;
  background-color: #d0eefa;
}
.btn:hover,
.btn-large:hover,
.btn-small:hover {
  background-color: #0097e6;
  /* background-color: #0984e3; */
}
